import styled from "styled-components";
import { useEffect, useRef, useState } from "react";

const Container = styled.div`
	position: relative;
	width: 348px;
	padding: 8px 12px;
	background-color: var(--color-background-light);
	border-radius: 12px;
	box-shadow: 0 0 6px var(--color-shadow);
	margin: 12px 0;
	-webkit-user-select: none; /* Safari */
	user-select: none;
`;

const ControlContainer = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	height: 56px;

	canvas {
		cursor: ${(props) => (props.isEnabled ? "pointer" : "not-allowed")};
		${(props) => (props.isEnabled ? "" : "pointer-events: none;")}
	}
`;

const DownloadContainer = styled.a`
	display: flex;
    gap: 6px;
	overflow: hidden;
	width: fit-content;
	max-height: ${(props) => (props.isEnabled ? "100px" : "")};
	margin-left: auto;
    padding: 0 12px;
	color: var(--color-text) !important;
	text-decoration: none !important;
    font-size: 14px;
    font-weight: normal;
    line-height: 14px;
    letter-spacing: 0;
	cursor: ${(props) => (props.isEnabled ? "pointer" : "not-allowed")};
	${(props) => (props.isEnabled ? "" : "pointer-events: none;")}
	transition: 0.3s;

	&:hover {
		color: var(--color-player) !important;
	}
`;

const PlayButton = styled.div`
	display: flex;
	width: 48px;
	height: 48px;
	justify-content: center;
	align-items: center;
	padding: 12px;
	cursor: pointer;

	div {
		flex: 1 1;
		width: 18px;
		height: 100%;
		background: var(--color-player);
		transition: 0.3s;
	}

	div:first-child {
		clip-path: ${(props) =>
		props.isPlaying
			? "polygon(0 0, 70% 0, 70% 100%, 0 100%)"
			: "polygon(0 0, 100% 25%, 100% 75%, 0% 100%)"};
		border-right: 0px solid var(--color-player);
	}

	div:last-child {
		clip-path: ${(props) =>
		props.isPlaying
			? "polygon(30% 0, 100% 0, 100% 100%, 30% 100%)"
			: "polygon(0 25%, 100% 50%, 100% 50%, 0 75%)"};
		border-left: 1px solid var(--color-player);
	}
`;

const Loading = styled.div`
	display: flex;
	width: 48px;
	height: 48px;
	justify-content: center;
	jusify-content;
	align-items: center;
	padding: 12px;

	div {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		background: radial-gradient(farthest-side, #B68A26 94%, #0000) top / 6px 6px no-repeat,
				conic-gradient(#0000 30%, #B68A26);
		-webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 6px), #000 0);
		animation: spinner-c7wet2 1s infinite linear;
	}

	@keyframes spinner-c7wet2 {
		100% {
			transform: rotate(1turn);
		}
	}
`;

const Error = styled.div`
	display: flex;
	width: 100%;
	gap: 12px;
	color: var(--color-error);
	font-weight: bold;
	justify-content: center;
	align-items: center;

	i {
		width: 16px;
		height: 16px;
	}
`;

const ShowTime = styled.div`
	margin: 0 auto;
`;

const LoadState = {
	PRELOAD: "preload",
	LOADING: "loading",
	SUCCESS: "success",
	ERROR: "error",
};

let currentPlayingAudio = null;
const audioContextPool = [];

const getAudioContext = () => {
	if (audioContextPool.length > 0) {
		return audioContextPool.pop();
	} else {
		return new (window.AudioContext || window.webkitAudioContext)();
	}
};

const releaseAudioContext = (audioContext) => {
	audioContextPool.push(audioContext);
};

function MusicPlayer({ file, gap = 8, autobuffer = true }) {
	const audioRef = useRef(null);
	const canvasRef = useRef(null);

	const [loadState, setLoadState] = useState(autobuffer ? LoadState.LOADING : LoadState.PRELOAD);
	const [isPlaying, setIsPlaying] = useState(false);
	const [currentTime, setCurrentTime] = useState(0);
	const [sampledData, setSampledData] = useState([]);
	const [isDragging, setIsDragging] = useState(false);
	const [autoPlay, setAutoPlay] = useState(false);

	const initialize = () => {
		// Load the data on audio component.
		const audio = new Audio(file);
		audioRef.current = audio;

		const audioContext = getAudioContext();

		const fetchAndDecodeAudio = async () => {
			try {
				const response = await fetch(file);
				const arrayBuffer = await response.arrayBuffer();
				const decodedData = await audioContext.decodeAudioData(
					arrayBuffer
				);

				const canvasWidth = canvasRef.current
					? canvasRef.current.width
					: 200; // Default width if canvas is not available

				// Get the audio data.
				const sampleData = getAudioData(decodedData, canvasWidth, gap);
				setSampledData(sampleData);
				drawLines(sampleData, 0, audio.duration);

				// Set state success to make playable the component.
				setLoadState(LoadState.SUCCESS);

				// Autoplaying if needed
				if (autoPlay && audio) {
					if (currentPlayingAudio && currentPlayingAudio !== audio) {
						currentPlayingAudio.pause();
					}

					audio.play();
					currentPlayingAudio = audio;
					setIsPlaying(true);
				}
			} catch (err) {
				console.error("Error decoding audio data:", err);
				setLoadState(LoadState.ERROR);
			}
		};

		// Start fetching audio data when file is loaded and decoded successfully.
		fetchAndDecodeAudio();

		return () => {
			releaseAudioContext(audioContext);
		};
	};

	useEffect(() => {
		console.log(loadState);

		if (loadState === LoadState.LOADING) {
			initialize();
		} else if (loadState === LoadState.PRELOAD) {
			drawDots();
		}
	}, [loadState]);

	useEffect(() => {
		if (!audioRef.current) return;

		const audio = audioRef.current;

		const handleTimeUpdate = () => {
			if (isDragging) return;

			setCurrentTime(audio.currentTime);
			drawLines(sampledData, audio.currentTime, audio.duration);
		};

		const handlePlayPause = () => {
			setIsPlaying(!audio.paused);
		};

		audio.addEventListener("timeupdate", handleTimeUpdate);
		audio.addEventListener("play", handlePlayPause);
		audio.addEventListener("pause", handlePlayPause);

		return () => {
			audio.removeEventListener("timeupdate", handleTimeUpdate);
			audio.removeEventListener("play", handlePlayPause);
			audio.removeEventListener("pause", handlePlayPause);
		};
	}, [sampledData, isDragging]);

	const getAudioData = (audioBuffer, canvasWidth, gap) => {
		const channelData = audioBuffer.getChannelData(0); // Assuming mono audio

		// Calcultae the number of lines can been drawed on canvas
		const numLines = Math.floor(canvasWidth / gap);

		// Calculate the sampleInterval based on the number of lines and the audio duration
		const sampleInterval = Math.floor(channelData.length / numLines);

		const samples = [];
		for (let i = 0; i < channelData.length; i += sampleInterval) {
			samples.push(channelData[i]);
		}

		return normalizeSamples(samples);
	};

	const normalizeSamples = (samples) => {
		const maxSample = Math.max(...samples);
		const minSample = Math.min(...samples);
		const range = maxSample - minSample;

		return samples.map((sample) => ((sample - minSample) / range) * 2 - 1);
	};

	const drawLines = (samples, currentTime, duration) => {
		if (!canvasRef.current) return 1;

		const canvas = canvasRef.current;
		const ctx = canvas.getContext("2d");
		const width = canvas.width;
		const height = canvas.height;
		const centerY = height / 2;

		const progress = currentTime / duration;

		ctx.clearRect(0, 0, width, height);
		ctx.strokeStyle = getComputedStyle(document.documentElement)
			.getPropertyValue("--color-background-player")
			.trim();
		ctx.lineWidth = 3;
		ctx.lineCap = "round";

		samples.forEach((sample, i) => {
			const x = i * gap + 2;
			const lineHeight = (sample * (height - 8)) / 2;

			ctx.beginPath();
			ctx.moveTo(x, centerY);
			ctx.lineTo(x, centerY - lineHeight);
			ctx.moveTo(x, centerY);
			ctx.lineTo(x, centerY + lineHeight);
			ctx.stroke();
		});

		// Draw the progress lines
		ctx.strokeStyle = getComputedStyle(document.documentElement)
			.getPropertyValue("--color-player")
			.trim();

		// Create a mask for the progress
		ctx.save();
		ctx.beginPath();
		ctx.rect(0, 0, width * progress, height);
		ctx.clip();

		samples.forEach((sample, i) => {
			const x = i * gap + 2;
			const lineHeight = (sample * (height - 8)) / 2;

			ctx.beginPath();
			ctx.moveTo(x, centerY);
			ctx.lineTo(x, centerY - lineHeight);
			ctx.moveTo(x, centerY);
			ctx.lineTo(x, centerY + lineHeight);
			ctx.stroke();
		});

		ctx.restore();
	};

	const drawDots = () => {
		if (!canvasRef.current) return 1;

		const canvas = canvasRef.current;
		const ctx = canvas.getContext("2d");
		const width = canvas.width;
		const height = canvas.height;
		const centerY = height / 2;

		ctx.clearRect(0, 0, width, height);
		ctx.strokeStyle = getComputedStyle(document.documentElement)
			.getPropertyValue("--color-background-player")
			.trim();
		ctx.lineWidth = 3;
		ctx.lineCap = "round";

		var x = 2;
		while (x < width) {
			// add dots
			ctx.beginPath();
			ctx.moveTo(x, centerY);
			ctx.lineTo(x, centerY);
			ctx.stroke();

			x += gap;
		}
	};

	const togglePlayPause = () => {
		if (!audioRef.current) return;

		const audio = audioRef.current;

		if (isPlaying) {
			audio.pause();
		} else {
			if (currentPlayingAudio && currentPlayingAudio !== audio) {
				currentPlayingAudio.pause();
			}
			audio.play();
			currentPlayingAudio = audio;
		}
	};

	/* Event listeners [START] */
	const handlePlayPauseButtonClick = () => {
		switch (loadState) {

			// When the audio is correctly buffered, you can toggle the play pause button
			case LoadState.SUCCESS:
				setIsPlaying(togglePlayPause);
				break;

			// If autobuffer is not enabled, you have to buffer the audio before playing
			case LoadState.PRELOAD:
				setAutoPlay(true);
				setLoadState(LoadState.LOADING);
				break;

			default:
				break;
		}
	}

	const handleMouseDown = (event) => {
		setIsDragging(true);
		updateCurrentTime(event.clientX);
	};

	const handleMouseMove = (event) => {
		if (isDragging) {
			updateCurrentTime(event.clientX);
		}
	};

	const handleMouseUp = () => {
		setIsDragging(false);
	};

	const handleTouchStart = (event) => {
		setIsDragging(true);
		updateCurrentTime(event.touches[0].clientX);
	};

	const handleTouchMove = (event) => {
		if (isDragging) {
			updateCurrentTime(event.touches[0].clientX);
		}
	};

	const handleTouchEnd = () => {
		setIsDragging(false);
	};
	/* Event listeners [END] */

	const updateCurrentTime = (clientX) => {
		if (!canvasRef.current) return;

		const canvas = canvasRef.current;
		const rect = canvas.getBoundingClientRect();
		const x = clientX - rect.left;
		const duration = audioRef.current.duration;
		const newTime = (x / canvas.width) * duration;

		audioRef.current.currentTime = newTime;
		setCurrentTime(newTime);
		drawLines(sampledData, newTime, duration);
	};

	return (
		<Container isEnabled={loadState === LoadState.SUCCESS}>
			{loadState === LoadState.ERROR ? (
				<Error>
					<i class="fi fi-sr-exclamation"></i>
					Impossibile caricare il file
				</Error>
			) : (
				<>
					<ControlContainer>
						{loadState !== LoadState.LOADING ? (
							<PlayButton
								onClick={handlePlayPauseButtonClick}
								isPlaying={isPlaying}
							>
								<div></div>
								<div></div>
							</PlayButton>
						) : (
							<Loading>
								<div></div>
							</Loading>
						)}
						<canvas
							ref={canvasRef}
							width={200}
							height={56}
							onMouseDown={handleMouseDown}
							onMouseMove={handleMouseMove}
							onMouseUp={handleMouseUp}
							onMouseLeave={handleMouseUp}
							onTouchStart={handleTouchStart}
							onTouchMove={handleTouchMove}
							onTouchEnd={handleTouchEnd}
						/>
						<ShowTime>
							{Math.floor(currentTime / 60)}:
							{Math.floor(currentTime % 60)
								.toString()
								.padStart(2, "0")}{" "}
						</ShowTime>
					</ControlContainer>
					<DownloadContainer 
						href={file}
						download	
						isEnabled={loadState === LoadState.SUCCESS}>
						Scarica
						<i class="fi fi-sr-download"></i>
					</DownloadContainer>	
				</>
			)}
		</Container>
	);
}

export default MusicPlayer;
