import styled from "styled-components";
import Header from "../components/common/HeaderComponent";
import ProfileCard from "../components/profiles/ProfileCard";

import schizzoImg from "../images/profiles/schizzo.jpg";
import tizImg from "../images/profiles/tiz.jpg";
import patrikImg from "../images/profiles/patrik.jpg";
import fedeImg from "../images/profiles/fede.jpg";
import shaalinImg from "../images/profiles/shaalin.jpg";
import blondyImg from "../images/profiles/blondy.jpg";
import meliImg from "../images/profiles/meli.jpg";
import mazzImg from "../images/profiles/mazz.jpg";

const CommiteePageContainer = styled.div`
	position: relative;
	padding-bottom: 48px;
`;

const CommiteeGrid = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 24px;
	justify-content: space-around;
`;

function CommiteePage() {
	return (
		<CommiteePageContainer>
			<Header title="Il Comitato" />
			<CommiteeGrid>
				<ProfileCard
					name={"Davide Cereghetti"}
					nikname={"Ul president, detto Schizzo"}
					img={schizzoImg}
					job={"Responsabile carro"}
				>
					<a href="tel:+41797086180">
						<i class="fi fi-sr-phone-call"></i> +41 79 708 61 80
					</a>
				</ProfileCard>
				<ProfileCard
					name={"Tiziano Solcà"}
					nikname={"detto Tiz"}
					img={tizImg}
					job={"Segretario"}
				/>
				<ProfileCard
					name={"Patrik Briccola"}
					nikname={"detto Orsetto"}
					img={patrikImg}
					job={"Responsabile trasporti"}
				/>
				<ProfileCard
					name={"Federico Livio"}
					nikname={"detto Fede"}
					img={fedeImg}
					job={"Responsabile costruzione carro"}
				/>
				<ProfileCard
					name={"Shaalin Valsecchi"}
					nikname={"detta Cucca"}
					img={shaalinImg}
					job={"Responsabile costumi"}
				/>
				<ProfileCard
					name={"Tommaso Blondel"}
					nikname={"detto Blondy"}
					img={blondyImg}
					job={"Responsabile manifestazioni"}
				/>
				<ProfileCard
					name={"Melissa Fontana"}
					nikname={"detta Meli"}
					img={meliImg}
					job={"Responsabile giornalino di carnevale"}
				/>
				<ProfileCard
					name={"Davide Mazzetti"}
					nikname={"detto Mazz"}
					img={mazzImg}
					job={"Cassiere"}
				/>
			</CommiteeGrid>
		</CommiteePageContainer>
	);
}

export default CommiteePage;
