import styled from "styled-components";
import Header from "../../components/common/HeaderComponent";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Card from "../../components/common/CardComponent";
import MusicPlayer from "../../components/music/MusicPlayer";
import { CaroselAlbum } from "../../components/objects/CaroselComponent";
import NoData from "../../images/nodata_rafiki.svg";
import YouTube from "react-youtube";

const ProcessionViewPageContainer = styled.div`
	position: relative;
	margin-bottom: 48px;

	h2 {
		color: #888888;
		font-size: 24px;
		font-weight: bold;
		letter-spacing: 0px;
		line-height: 20px;
		margin: 24px 0 12px;
	}

	ul {
		padding-inline-start: 24px;
	}
`;

const ItemHeader = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	div.split-part {
		flex: 1 1;
		display: flex;
		justify-content: center;
		align-items: center;
		min-width: 230px;

		img {
			width: 230px;
			height: 230px;
			object-fit: contain;
		}
	}
`;

const ProcessionRanking = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	table {
		margin-top: 24px;

		span {
			font-size: 14px;
			font-weight: 500;
			letter-spacing: 0px;
			line-height: 20px;
		}
	}
`;

const ExtraGrid = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 12px;
	justify-content: space-around;

	iframe, video {
		width: 336px;
		height: 220px;
		border-radius: 6px;
	}
`;

const ErrorPageContainer = styled.div`

	img {
		max-height: 450px;
		width: 100%;
		object-fit: contain;
	}
`;

function ProcessionViewPage() {
	const { name } = useParams();
	const regex = /^(\d+){4}/;
	const matches = name ? name.match(regex) : null;
	const [item, setItem] = useState(null);
	const [error, setError] = useState(null);
	const [id, setId] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (matches && matches.length >= 2) {
			console.log("Controllo matching...");
			setId(matches[0]);
			setError(null);
		} else {
			console.error("No match found or name is undefined!");
			setId(null);
			setError("Sembra che non abbiamo partecipato a questo corteo!");
		}
	}, [name, matches]);

	console.log("Parametro name:", name, "ID estratto:", id);

	useEffect(() => {
		if (id) {
			setLoading(true);

			fetch(`/json/cortei.json`)
				.then((response) => {
					if (!response.ok) {
						throw new Error(`HTTP error! status: ${response.status}`);
					}
					return response.json();
				})
				.then((data) => {
					const selectedItem = data.find((item) => item.year === id);
					console.log("Elemento selezionato:", selectedItem);

					if (selectedItem) {
						setItem(selectedItem);
						setError(null);
					} else {
						console.error("Nessun elemento trovato con questo ID.");
						setItem(null);
                        setError("Sembra che non abbiamo partecipato a questo corteo!");
					}
				})
				.catch((error) => {
					console.error("Fetch error:", error);
					setItem(null);
					setError("Si è verificato un errore durante il caricamento dei dati.");
				})
				.finally(() => {
					setLoading(false);
				});
		} else {
			console.error("Nessun ID trovato.");
			setItem(null);
			setError("Sembra che non abbiamo partecipato a questo corteo!");
			setLoading(false);
		}
	}, [id]);

	return (
		<ProcessionViewPageContainer>
			{loading && <Header title="Caricamento in corso..." />}
			{error && errorPage(error)}
			{(!error && item) && loadItemPage(item)}
			{(!error && !item && !loading) && <Header title={`${id ? id + ' - ' : ''}Corteo non trovato`} />}
		</ProcessionViewPageContainer>
	);
}

function loadItemPage(item) {
	console.log(item);

	return (
		<div>
			<Header title={`${item.year} - ${item.title}`} goBack={true} />
			<ItemHeader>
				<div className="split-part">
					<img src={item.image} alt={item.title} loading="lazy" />
				</div>
				<div className="split-part">
					<Card>
						<h3>Classifica</h3>
						<ProcessionRanking>
							<table>
								{item.ranking.map((item, index) => (
									<tr>
										<td key={index}>
											<span
												key={index}
											>{`${item.position}° posto a ${item.where}`}</span>
										</td>
									</tr>
								))}
							</table>
						</ProcessionRanking>
					</Card>
				</div>
			</ItemHeader>
			{item.notopic ? <div></div> : <h2>Tema</h2>}
			<p dangerouslySetInnerHTML={{ __html: item.topic }} />
			<CaroselAlbum year={item.year} />
			<h2>Musica</h2>
			<p>{`Ascolta la Hit del carnevale ${item.year}!`}</p>
			<MusicPlayer file={item.music} />
			{item.extras ? (
				<div>
					<h2>Extra</h2>
					<ExtraGrid>
						{item.extras.map((extra, index) => {
							if (extra.type === "youtube") {
								return <YouTube key={index} videoId={extra.videoId} />;
							} else if (extra.type === "video") {
								return <video key={index} src={extra.videoId} controls />;
							}
							return <></>;
						})}
					</ExtraGrid>
				</div>
			) : (
				<></>
			)}
		</div>
	);
}

function errorPage(message) {
	return (
		<ErrorPageContainer>
			<Header title=":(" goBack={true} />
			<h2>Si è verificato un errore</h2>
			<p>{message} <Link to="/cortei">Torna alla pagina dei cortei.</Link></p>
			<img src={NoData} alt="NoData freepik" />
		</ErrorPageContainer>
	);
}

export default ProcessionViewPage;
